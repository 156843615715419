var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$can('update', _vm.modulo)
    ? _vm.data.item.estado == 'Pendiente'
      ? _vm.permisoDescuento
      : false
    : false)?_c('b-button',{staticClass:"btn-sm",attrs:{"variant":"flat-danger","title":_vm.$can('update', _vm.modulo)
    ? 'Aprobar'
    : ''},on:{"click":function($event){_vm.$can('update', _vm.modulo)
    ? _vm.$emit('processUpdateEstado', _vm.data.item)
    : ''}}},[_vm._v(" "+_vm._s(_vm.data.item.estado)+" ")]):_c('b-badge',{staticClass:"text-center",attrs:{"variant":_vm.data.item.estado == 'Aprobado'
    ? 'light-success'
    : 'light-danger'}},[_vm._v(" "+_vm._s(_vm.data.item.estado)+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }