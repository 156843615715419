<template>
  <b-button
    v-if="$can('update', modulo)
      ? data.item.estado == 'Pendiente'
        ? permisoDescuento
        : false
      : false"
    variant="flat-danger"
    :title="$can('update', modulo)
      ? 'Aprobar'
      : ''"
    @click="$can('update', modulo)
      ? $emit('processUpdateEstado', data.item)
      : ''"
    class="btn-sm"
  >
    {{ data.item.estado }}
  </b-button>

  <b-badge
    v-else
    :variant="data.item.estado == 'Aprobado'
      ? 'light-success'
      : 'light-danger'"
    class="text-center"
  >
    {{ data.item.estado }}
  </b-badge>
</template>

<script>
import {
  BButton, BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BBadge,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    modulo: {
      type: String,
      required: true,
    },
    getUser: {
      type: Object,
      required: true,
    },
  },
  computed: {
    permisoDescuento() {
      let result = false
      if (this.getUser.tipoUsuario === 'Admin'
        || this.getUser.tipoUsuario === 'Bodeguero') {
        result = true
      }
      return result
    },
  },
}
</script>
